@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

div.poem-count-animation {
  animation: counter 5s 1 forwards ease-in-out;
  counter-reset: num var(--num);
  /* font: 800 40px system-ui; */
  /* padding: 2em; */
}

div.poem-count-animation::after {
  content: counter(num);
}

div.anthology-count-animation {
  animation: counter 5s 1 forwards ease-in-out;
  counter-reset: num var(--num);
  /* font: 800 40px system-ui; */
  /* padding: 2em; */
}

div.anthology-count-animation::after {
  content: counter(num);
}
